import React from 'react';
import PropTypes from "prop-types";
import Layout from "../containers/layout/layout";
import Seo from "../components/seo";
import Text from "../components/shared/text";
import Heading from "../components/shared/heading";
import styled from "styled-components";
import {device} from "../theme";
import {BtnWrap} from "../containers/layout/block-text/block-text.stc";


function FollowUs() {
    return (
        <Layout lang="ar" url="/follow-us" headerStyle="darkHeader" hideFooter={true}>
            <Seo lang="ar" title="تابعنا !"/>
            <div className="row align-items-center">
                <div className="col-2 offset-1">
                    <FollowUsWrap>
                        <div className="rn-project-meta-inner">
                        </div>
                        <FollowUsContentWrap>
                            <Text>مرحبا بكم معنا</Text>
                            <Heading>ندعوكم لمتابعتنا عبر المنصات التالية</Heading>
                            <BtnWrap href={"https://salamandre.ma"} mb={"10px"} fw={600} width={"100%"}>موقعنا الإلكتروني</BtnWrap>
                            <BtnWrap target="_blank" href={"https://www.facebook.com/as.salamandre"} mb={"10px"} width={"100%"} color={"#fff!important"} bgColor={"#3B5998"} fw={600}>فايسبوك</BtnWrap>
                            <BtnWrap target="_blank" href={"https://www.instagram.com/as.salamandre/"} mb={"10px"} width={"100%"} color={"#fff!important"} bgColor={"#D82E86"} fw={600}>إنستجرام</BtnWrap>
                            <BtnWrap target="_blank" href={"https://www.linkedin.com/in/association-salamandre-9443972b5"} color={"#fff!important"} width={"100%"} mb={"10px"} bgColor={"#00ACEE"} fw={600}>لينكد إن</BtnWrap>
                        </FollowUsContentWrap>
                    </FollowUsWrap>
                </div>
            </div>
        </Layout>
    );
}

FollowUs.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

FollowUs.defaultProps = {
    FormContainer: {
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        width: '300px'
    },
    TextStyle: {
        lineHeight: '26px'
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '14px',
        fontweight: 700,
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        paddingRight: '10px',
        fontSize: '13px',
        color: '#000000'
    }
}


export const FollowUsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  height: 100vh;
`;

export const FollowUsContentWrap = styled.div`
  margin-top: 70px;
  @media ${device.medium}{
    margin-top: 50px;
  }
`;



export default FollowUs;
